// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyAuMODR-Fg9mnbe6hXonHBMYl2xHsF5_3w',
    authDomain: 'troiscda.firebaseapp.com',
    databaseURL: 'https://troiscda.firebaseio.com',
    projectId: 'troiscda',
    storageBucket: 'troiscda.appspot.com',
    messagingSenderId: '172549616460'
  },
  mapbox: {
    accessToken: 'pk.eyJ1IjoiYmxpc3NtZWRpYSIsImEiOiJjanJ0bnJxaW8wdms3NDNxczdhb3JhYnBsIn0.ICTfUHB6Er_IpJiUmRo97g',
    style : 'mapbox://styles/blissmedia/cjrtnsn9e9tsf2so3vvs0getl'
  },
  googleMapsKey: 'AIzaSyCyu2VMi6i4eO2V1Xc0M_TnkoHcZRAGdCk',
  avatar : './assets/avatar.jpg',
  linkPreviewURL: 'http://api.johndoe-et-fils.com/preview/?url=',
  oneSignalKey: '215230c1-5624-4122-a244-581f7a828449', 
  oneSignalID: '172549616460',
  oneSignalAuth : 'ZmUyZWNkODEtOWMxNy00OTBlLTg2MjYtYWFlMTFmOTE2NzA1'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
